define('oddset-cms/components/version-radio-button-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    initSelectedFilter: 'all',
    classNames: ['btn-group'],
    action: function action() {},

    // Private
    selectedFilter: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('selectedFilter', this.get('initSelectedFilter'));
    },


    actions: {
      didSelectFilter: function didSelectFilter(value) {
        this.set('selectedFilter', value);
        this.get('action')(value);
      }
    }
  });
});