define('oddset-cms/controllers/racing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),

    isLoaded: false,
    turnOffVersion: '3.0.0',
    releasedAppVersion: '2.4.0',
    minHundOgHestVersion: null,

    isHundOgHestTurnedOn: function () {
      return this.get('minHundOgHestVersion') <= this.get('releasedAppVersion');
    }.property('minHundOgHestVersion'),

    init: function init() {
      var _this = this;

      this.get('ajax').request('/racing_switch').then(function (response) {
        var minHundOgHestVersion = response.min_version;
        _this.updateLayout(minHundOgHestVersion);
      });
    },
    updateLayout: function updateLayout(version) {
      this.set('isLoaded', true);
      this.set('minHundOgHestVersion', version);
    },


    actions: {
      turnOnRacing: function turnOnRacing() {
        console.log("Setting min version to: " + this.get('releasedAppVersion'));
        this.get('ajax').post('/racing_switch/' + this.get('releasedAppVersion'));
        this.updateLayout(this.get('releasedAppVersion'));
      },
      turnOffRacing: function turnOffRacing() {
        this.get('ajax').post('/racing_switch/' + this.get('turnOffVersion'));
        this.updateLayout(this.get('turnOffVersion'));
      }
    }
  });
});