define('oddset-cms/components/banner-medium-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    format: null,

    showLoadingIcon: false,
    showSuccess: false,
    showError: false,

    saveFormat: function saveFormat() {
      var _this = this;

      var model = this.get('format');

      if (model.get('hasDirtyAttributes')) {
        this.set('showError', false);

        model.save().then(function () {
          _this.set('showSuccessIcon', true);
          setTimeout(function () {
            _this.set('showSuccessIcon', false);
          }, 750);
        }).catch(function () {
          _this.set('showError', true);
        }).finally(function () {
          _this.set('showLoadingIcon', false);
        });
      } else {
        this.set('showLoadingIcon', false);
      }
    },

    actions: {
      submitFormat: function submitFormat() {
        this.set('showLoadingIcon', true);
        Ember.run.debounce(this, this.saveFormat, 1000);
      }
    }
  });
});