define('oddset-cms/controllers/alerts', ['exports', 'ember-sweetalert/mixins/sweetalert-mixin'], function (exports, _sweetalertMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sweetalertMixin.default, {
    alertAjax: Ember.inject.service(),

    alerts: Ember.computed.alias('model'),

    showEarlierStatusMessages: false,
    isShowingCreateAlertForm: false,

    activeStatusMessages: Ember.computed.filterBy('alerts', 'isActive'),
    inactiveStatusMessages: Ember.computed.filterBy('alerts', 'isArchived'),
    upcomingStatusMessages: Ember.computed.filterBy('alerts', 'isUpcoming'),
    sortedInactiveStatusMessages: Ember.computed.sort('inactiveStatusMessages', function (a, b) {
      return b.get('createdAt') - a.get('createdAt');
    }),

    _sweetAlertConfirm: function _sweetAlertConfirm(title, confirmText, callback) {
      var sweetAlert = this.get('sweetAlert');
      return sweetAlert({
        title: title,
        type: 'info',
        confirmButtonColor: "#E47834",
        reverseButtons: true,
        confirmButtonText: confirmText,
        cancelButtonText: "Cancel",
        showCancelButton: true
      });
    },


    actions: {
      toggleEarlierStatusMessages: function toggleEarlierStatusMessages() {
        this.toggleProperty('showEarlierStatusMessages');
      },
      showCreateAlertForm: function showCreateAlertForm() {
        this.set('isShowingCreateAlertForm', true);
      },
      cancelCreateAlert: function cancelCreateAlert() {
        this.set('isShowingCreateAlertForm', false);
      },
      createAlert: function createAlert(alertParams) {
        var _this = this;

        this.store.createRecord('alert', alertParams).save().then(function () {
          _this.store.findAll('alert', { reload: true });
        }).then(function () {
          _this.set('isShowingCreateAlertForm', false);
        });
      },
      deleteAlert: function deleteAlert(alert) {
        alert.destroyRecord();
      },
      activateAlert: function activateAlert(alert) {
        var _this2 = this;

        return this._sweetAlertConfirm('Are you sure you want to enable the status message?', 'Yes, actvate it!').then(function (confirm) {
          if (confirm) {
            var ajax = _this2.get('alertAjax');

            return ajax.put('/alerts/' + alert.get('id') + '/activations').finally(function () {
              alert.reload();
            });
          }
        });
      },
      deactivateAlert: function deactivateAlert(alert) {
        var _this3 = this;

        return this._sweetAlertConfirm('Are you sure you want to deactivate the status message?', 'yes, deactivate it!').then(function (confirm) {
          if (confirm) {
            var ajax = _this3.get('alertAjax');

            return ajax.put('/alerts/' + alert.get('id') + '/deactivations').finally(function () {
              alert.reload();
            });
          }
        });
      }
    }
  });
});