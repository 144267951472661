define('oddset-cms/adapters/application', ['exports', 'oddset-cms/config/environment', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _environment, _activeModelAdapter, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.Api.host,
    namespace: _environment.default.Api.namespace,
    pathForType: function pathForType(modelName) {
      var decamelized = Ember.String.underscore(modelName);
      return Ember.String.pluralize(decamelized);
    },
    authorizer: 'authorizer:oauth2-bearer'
  });
});