define("oddset-cms/transforms/utc", ["exports", "moment", "ember-data"], function (exports, _moment, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value ? value.toJSON() : null;
    },

    deserialize: function deserialize(value) {
      return value ? (0, _moment.default)(value) : null;
    }
  });
});