define('oddset-cms/router', ['exports', 'oddset-cms/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // Auth routes
    this.route('callback');
    this.route('login');

    // Secured routes
    this.route('cms', { path: '' }, function () {
      this.route('module-group-specs', { path: '/module_group_specs', resetNamespace: true });
      this.route('module-group-spec', { path: '/module_group_specs/:module_group_spec_id', resetNamespace: true });
      this.route('versions', { resetNamespace: true });
      this.route('version', { path: '/versions/:version_id', resetNamespace: true });
      this.route('banners', { resetNamespace: true }, function () {
        this.route('banner', { path: '/:banner_id' });
      });
      this.route('alerts', { resetNamespace: true });
      this.route('racing', { resetNamespace: true });
    });
  });

  exports.default = Router;
});