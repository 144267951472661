define('oddset-cms/models/audit-log-entry', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    eventType: _emberData.default.attr(),
    cmsUserEmail: _emberData.default.attr(),
    cmsUserName: _emberData.default.attr(),
    createdAt: _emberData.default.attr('utc'),
    meta: _emberData.default.attr('raw'),

    isCreateOperation: Ember.computed.equal('eventType', 'CREATED'),
    isUpdateOperation: Ember.computed.equal('eventType', 'UPDATED')
  });
});