define('oddset-cms/components/module-spec-toolbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    moduleGroupSpec: null,
    isOpen: false,
    classNames: 'module-spec-toolbox',

    classNameBindings: ["isOpen:module-spec-toolbox--open"],

    close: function close() {
      this.set('isOpen', false);
    },


    actions: {
      close: function close() {
        this.close();
      },
      addModuleSpec: function addModuleSpec(moduleSpec) {
        this.get('addModuleSpec')(moduleSpec);
        this.close();
      }
    }
  });
});