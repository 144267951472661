define('oddset-cms/services/alert-ajax', ['exports', 'ember-ajax/services/ajax', 'oddset-cms/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    namespace: _environment.default.AlertApi.namespace,
    contentType: 'application/json; charset=utf-8',
    host: _environment.default.AlertApi.host,
    headers: Ember.computed('session.data.authenticated.id_token', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.data.authenticated.id_token');
        if (authToken) {
          headers['Authorization'] = 'Bearer ' + authToken;
        }
        return headers;
      }
    })
  });
});