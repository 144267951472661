define('oddset-cms/components/status-alert-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    createAction: function createAction() {},
    cancelAction: function cancelAction() {},

    selectedLevel: null,
    text: null,
    activatedAt: null,
    deactivatedAt: null,
    classNames: 'card mb-3',
    classNameBindings: ['cardBackground', 'useWhiteTextColor:text-white'],
    moment: Ember.inject.service(),

    error: null,
    hasError: Ember.computed.notEmpty('error'),

    minActivationDate: function minActivationDate() {
      return this.get('moment')().subtract(15, 'minute');
    },


    cardBackground: function () {
      switch (this.get('selectedLevel')) {
        case 'success':
          return 'bg-success';
        case 'info':
          return 'bg-light';
        case 'warning':
          return 'bg-warning';
        case 'error':
          return 'bg-danger';
      }
    }.property('selectedLevel'),

    useWhiteTextColor: function () {
      return Ember.isPresent(this.get('selectedLevel')) && this.get('selectedLevel') != 'info';
    }.property('selectedLevel'),

    levelOptions: ['info', 'success', 'warning', 'error'],

    getDatePickerDate: function getDatePickerDate(date) {
      if (date != null) {
        return date.get(0);
      } else {
        return null;
      }
    },
    isValid: function isValid() {
      return Ember.isPresent(this.get('selectedLevel')) && Ember.isPresent(this.get('text')) && Ember.isPresent('activatedAt');
    },


    actions: {
      saveAlert: function saveAlert() {
        var _this = this;

        this.set('error', null);
        setTimeout(function () {
          if (!_this.isValid()) {
            _this.set('error', "At least one 'level', one 'text' and one 'activation_date' must be specified in order to create a status message");
            return;
          }
          _this.get('createAction')({
            level: _this.get('selectedLevel'),
            text: _this.get('text'),
            activatedAt: _this.getDatePickerDate(_this.get('activatedAt')),
            deactivatedAt: _this.getDatePickerDate(_this.get('deactivatedAt'))
          });
        }, 250);
      },
      cancel: function cancel() {
        this.get('cancelAction')();
      }
    }
  });
});