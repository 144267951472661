define('oddset-cms/components/configurations/next-most-prominent-type-event-group-lists-spec-config', ['exports', 'oddset-cms/components/configurations/module-configuration'], function (exports, _moduleConfiguration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _moduleConfiguration.default.extend({
    selectedEventTypes: Ember.A(),

    didInsertElement: function didInsertElement() {
      this.set('selectedEventTypes', this.get('config.eventTypesExcluded'));
    },


    actions: {
      beforeSave: function beforeSave() {
        this.set('config.eventTypesExcluded', this.get('selectedEventTypes'));
      }
    }
  });
});