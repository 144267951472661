define('oddset-cms/models/module-group-spec', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ModuleGroupSpecModuleSpecWithIndex = Ember.Object.extend({
    index: null,
    moduleGroupSpecModuleSpec: null
  });

  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    moduleSpecOptions: _emberData.default.hasMany('module-spec'),
    moduleGroupSpecModuleSpecs: _emberData.default.hasMany('module-group-spec-module-spec', { inverse: 'moduleGroupSpec' }),
    moduleLayoutSpecs: _emberData.default.hasMany('module-layout-spec'),

    moduleSpecs: Ember.computed.mapBy('moduleGroupSpecModuleSpecsWithIndex', 'moduleSpec'),

    indexForModuleGroupSpecModuleSpec: function indexForModuleGroupSpecModuleSpec(moduleGroupSpecModuleSpecId) {
      var mgsmsWithIndex = this.get('moduleGroupSpecModuleSpecsWithIndex').find(function (mgsms) {
        return mgsms.get('moduleGroupSpecModuleSpec.id') == moduleGroupSpecModuleSpecId;
      });
      return mgsmsWithIndex != undefined ? mgsmsWithIndex.get('index') : '?';
    },

    moduleGroupSpecModuleSpecsWithIndex: function () {
      return this.get('moduleGroupSpecModuleSpecs').map(function (mgsms, index) {
        return ModuleGroupSpecModuleSpecWithIndex.create({
          index: index + 1,
          moduleGroupSpecModuleSpec: mgsms
        });
      });
    }.property('moduleGroupSpecModuleSpecs.[]')
  });
});