define('oddset-cms/components/configurations/site-core-banner-module-spec-config', ['exports', 'oddset-cms/components/configurations/module-configuration'], function (exports, _moduleConfiguration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _moduleConfiguration.default.extend({
    fillColumns: Ember.computed.alias('config.fillColumns'),

    fillColumnsOptions: function () {
      return [1, 2, 3];
    }.property()
  });
});