define('oddset-cms/models/banner-media-format', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    url: _emberData.default.attr(),

    hasUrl: function () {
      return Ember.isPresent(this.get('url'));
    }.property('url')

  });
});