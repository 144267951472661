define('oddset-cms/components/status-alert', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    alert: null,
    deactivateNow: function deactivateNow() {},
    activateNow: function activateNow() {},
    delete: function _delete() {},

    isInactive: Ember.computed.alias('alert.isInactive'),

    classNames: 'card mb-3',
    classNameBindings: ['cardBackground', 'useWhiteTextColor:text-white', 'isInactive:alert-card__inactive'],

    cardBackground: function () {
      switch (this.get('alert.level')) {
        case 'success':
          return 'bg-success';
        case 'info':
          return 'bg-light';
        case 'warning':
          return 'bg-warning';
        case 'error':
          return 'bg-danger';
        default:
          return 'bg-secondary';
      }
    }.property('alert.level'),

    useWhiteTextColor: function () {
      return Ember.isPresent(this.get('alert.level')) && this.get('alert.level') != 'info';
    }.property('alert.level'),

    actions: {
      deactivateStatusMessage: function deactivateStatusMessage() {
        this.get('deactivateNow')(this.get('alert'));
      },
      activateStatusMessage: function activateStatusMessage() {
        this.get('activateNow')(this.get('alert'));
      },
      delete: function _delete() {
        this.get('delete')(this.get('alert'));
      }
    }
  });
});