define('oddset-cms/components/version-filter-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: null,
    selectedValue: null,
    action: function action() {},

    attributeBindings: ['type'],
    type: 'button',

    tagName: 'button',
    classNames: ['version__filter-button', 'btn', 'btn-secondary'],
    classNameBindings: ['isSelected:active'],

    isSelected: function () {
      return this.get('selectedValue') === this.get('value');
    }.property('selectedValue', 'value'),

    click: function click() {
      if (!this.get('isSelected')) {
        this.get('action')(this.get('value'));
      }
    }
  });
});