define('oddset-cms/controllers/version', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    version: Ember.computed.alias('model'),

    sortedAuditLogEntries: Ember.computed.sort('version.auditLogEntries', function (a, b) {
      return b.get('createdAt').diff(a.get('createdAt'));
    })
  });
});