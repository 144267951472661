define('oddset-cms/models/banner-medium', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    loop: _emberData.default.attr('boolean'),
    autoplay: _emberData.default.attr('boolean'),

    bannerMediaFormats: _emberData.default.hasMany('banner-media-format', { async: false }),

    formats: Ember.computed.sort('bannerMediaFormats', function (a, b) {
      return b.get('width') - a.get('width');
    }),

    largestFormatUrl: function () {
      return this.get('formatsWithUrl.firstObject.url');
    }.property('formatsWithUrl.@each.url'),

    hasUrls: function () {
      return this.get('formatsWithUrl.length') >= 1;
    }.property('formats.@each.url'),

    formatsWithUrl: function () {
      return this.get('formats').filter(function (format) {
        return format.get('hasUrl');
      });
    }.property('formats.@each.url'),

    numberOfFormatsWithUrl: Ember.computed.alias('formatsWithUrl.length'),
    totalNumberOfFormats: Ember.computed.alias('formats.length'),

    iconId: function () {
      switch (this.get('type')) {
        case 'IMAGE':
          return 'picture-o';
        case 'VIDEO':
          return 'video-camera';
        default:
          return 'question';
      }
    }.property('type')
  });
});