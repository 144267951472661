define('oddset-cms/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass("animate--cross-fade"), this.toValue(true), this.use('crossFade'), this.reverse('crossFade'));

    this.transition(this.hasClass("animate--fade"), this.toValue(true), this.use('fade', { duration: 250 }), this.reverse('fade', { duration: 250 }));

    this.transition(this.hasClass("animate--fade-up-down"), this.toValue(true), this.use('toDown', { duration: 250 }), this.reverse('toUp', { duration: 250 }));
  };
});