define('oddset-cms/controllers/module-group-spec', ['exports', 'ember-sweetalert/mixins/sweetalert-mixin'], function (exports, _sweetalertMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sweetalertMixin.default, {
    moduleGroupSpec: Ember.computed.alias('model'),
    isToolboxOpen: false,
    ajax: Ember.inject.service(),

    publish: function publish() {
      var _this = this;

      var ajax = this.get('ajax');

      return ajax.post('/module_group_specs/' + this.get('moduleGroupSpec.id') + '/publications').then(function () {
        var sweetAlert = _this.get('sweetAlert');
        sweetAlert({
          title: "In a few minutes all users will see the effects of the changes",
          type: "success"
        });
      }).finally(function () {
        _this.get('moduleGroupSpec').reload();
      });
    },
    reset: function reset() {
      var _this2 = this;

      var ajax = this.get('ajax');

      return ajax.post('/module_group_specs/' + this.get('moduleGroupSpec.id') + '/resets').finally(function () {
        _this2.get('moduleGroupSpec').reload();
      });
    },


    actions: {
      sortEndAction: function sortEndAction() {},
      openToolbox: function openToolbox() {
        this.set('isToolboxOpen', true);
      },
      addModuleSpec: function addModuleSpec(moduleSpec) {
        var _this3 = this;

        var moduleGroupSpecModuleSpec = this.get('store').createRecord('moduleGroupSpecModuleSpec', {
          moduleGroupSpec: this.get('moduleGroupSpec'),
          moduleSpec: moduleSpec
        });
        setTimeout(function () {
          moduleGroupSpecModuleSpec.save().then(function () {
            _this3.get('moduleGroupSpec').reload();
          });
        }, 500);
      },
      publishModuleGroupSpec: function publishModuleGroupSpec() {
        var _this4 = this;

        var sweetAlert = this.get('sweetAlert');
        sweetAlert({
          title: "Are you sure?",
          text: "After clicking \"Yes publish it!\" all users will see the content as it is defined here",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#E47834",
          confirmButtonText: "Yes publish it!",
          reverseButtons: true
        }).then(function (confirm) {
          if (confirm) {
            _this4.publish();
          }
        });
      },
      resetModuleGroupSpec: function resetModuleGroupSpec() {
        var _this5 = this;

        var sweetAlert = this.get('sweetAlert');
        sweetAlert({
          title: "Are you sure?",
          text: "Alle changes made after the last release will be lost",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, restore",
          reverseButtons: true
        }).then(function (confirm) {
          if (confirm) {
            _this5.reset();
          }
        });
      },
      removedModuleGroupSpec: function removedModuleGroupSpec(moduleGroupSpecModuleSpec) {
        var _this6 = this;

        moduleGroupSpecModuleSpec.destroyRecord().then(function () {
          _this6.get('moduleGroupSpec').reload();
        });
      }
    }
  });
});