define('oddset-cms/components/banner-medium', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    bannerMedium: null,

    numberOfFormatsWithUrl: Ember.computed.alias('bannerMedium.numberOfFormatsWithUrl'),
    totalNumberOfFormats: Ember.computed.alias('bannerMedium.totalNumberOfFormats'),

    allFormatsAreFilled: function () {
      return this.get('numberOfFormatsWithUrl') === this.get('totalNumberOfFormats');
    }.property('numberOfFormatsWithUrl', 'totalNumberOfFormats'),

    someFormatsAreFilled: function () {
      return !this.get('allFormatsAreFilled') && this.get('numberOfFormatsWithUrl') >= 1;
    }.property('numberOfFormatsWithUrl', 'totalNumberOfFormats'),

    statusColorClass: function () {
      if (this.get('allFormatsAreFilled')) {
        return 'text-success';
      } else if (this.get('someFormatsAreFilled')) {
        return 'text-warning';
      } else {
        return 'text-danger';
      }
    }.property('numberOfFormatsWithUrl', 'totalNumberOfFormats'),

    isFormatsOpen: false,
    actions: {
      toggleFormats: function toggleFormats() {
        this.toggleProperty('isFormatsOpen');
      }
    }
  });
});