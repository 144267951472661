define('oddset-cms/components/module-layout-spec-module-spec', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    moduleLayoutSpecModuleSpec: null,
    moduleGroupSpec: null,

    // Private
    isConfigurationOpen: false,
    isSaving: Ember.computed.alias('moduleLayoutSpecModuleSpec.isSaving'),
    isEnabled: Ember.computed.alias('moduleLayoutSpecModuleSpec.isEnabled'),
    isDisabled: Ember.computed.not('isEnabled'),
    showConfiguration: Ember.computed.and('isEnabled', 'moduleLayoutSpecModuleSpec.hasConfiguration'),
    classNames: ['module-layout-spec-module-spec'],
    classNameBindings: ['isDisabled:module-layout-spec-module-spec--is-disabled'],

    index: function () {
      return this.get('moduleGroupSpec').indexForModuleGroupSpecModuleSpec(this.get('moduleLayoutSpecModuleSpec.moduleGroupSpecModuleSpecId'));
    }.property('moduleLayoutSpecModuleSpec', 'moduleGroupSpec'),

    configurationComponentName: function () {
      return ['configurations', this.get('moduleLayoutSpecModuleSpec.moduleSpecConfiguration').constructor.modelName].join('/');
    }.property(),

    closeConfiguration: function closeConfiguration() {
      this.set('isConfigurationOpen', false);
    },


    actions: {
      openConfiguration: function openConfiguration() {
        this.set('isConfigurationOpen', true);
      },
      closeConfiguration: function closeConfiguration() {
        this.closeConfiguration();
      },
      saveConfiguration: function saveConfiguration() {
        var _this = this;

        this.get('moduleLayoutSpecModuleSpec').save().then(function () {
          _this.closeConfiguration();
        });
      },
      enable: function enable() {
        var moduleSpec = this.get('moduleLayoutSpecModuleSpec');
        moduleSpec.set('isEnabled', true);
        moduleSpec.save();
      },
      disable: function disable() {
        var moduleSpec = this.get('moduleLayoutSpecModuleSpec');
        moduleSpec.set('isEnabled', false);
        this.closeConfiguration();
        moduleSpec.save();
      }
    }
  });
});