define('oddset-cms/components/module-group-spec-module-spec', ['exports', 'ember-sweetalert/mixins/sweetalert-mixin'], function (exports, _sweetalertMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sweetalertMixin.default, {
    // Public
    moduleGroupSpecModuleSpecWithIndex: null,
    removedModuleGroup: function removedModuleGroup() {},

    // Private
    isSaving: Ember.computed.alias('moduleGroupSpecModuleSpec.isSaving'),
    moduleGroupSpecModuleSpec: Ember.computed.alias('moduleGroupSpecModuleSpecWithIndex.moduleGroupSpecModuleSpec'),
    classNames: ['module-group-spec-module-spec', 'card'],
    classNameBindings: ['isSaving:module-group-spec-module-spec--loading'],

    actions: {
      removeModuleGroupModuleSpec: function removeModuleGroupModuleSpec() {
        var _this = this;

        var sweetAlert = this.get('sweetAlert');
        sweetAlert({
          title: "Er du sikker?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ja, slet den",
          reverseButtons: true
        }).then(function (confirm) {
          if (confirm) {
            _this.get('removedModuleGroup')(_this.get('moduleGroupSpecModuleSpec'));
          }
        });
      }
    }
  });
});