define('oddset-cms/instance-initializers/session-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var applicationRoute = instance.lookup('route:application');
    var session = instance.lookup('service:session');
    session.on('authenticationSucceeded', function () {
      applicationRoute.transitionTo('module-group-specs');
    });
    session.on('invalidationSucceeded', function () {
      //todo this is a hack please review

      // applicationRoute.transitionTo('request-password');
    });
  }

  exports.default = {
    initialize: initialize,
    name: 'session-events',
    after: 'ember-simple-auth'
  };
});