define('oddset-cms/models/alert', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    level: _emberData.default.attr(),
    text: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    deactivatedAt: _emberData.default.attr('utc'),
    activatedAt: _emberData.default.attr('utc'),
    isActive: _emberData.default.attr('boolean'),
    isUpcoming: _emberData.default.attr('boolean'),

    isInactive: Ember.computed.not('isActive'),
    isNotUpcoming: Ember.computed.not('isUpcoming'),

    isArchived: Ember.computed.and('isInactive', 'isNotUpcoming'),

    hasDeactivationDate: Ember.computed.notEmpty('deactivatedAt'),

    isErrorLevel: Ember.computed.equal('level', 'error')
  });
});