define('oddset-cms/components/version-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    state: null,

    tagName: 'span',
    classNames: 'badge',
    classNameBindings: ['class', 'isReleased:badge-success', 'isInBeta:badge-warning', 'isOff:badge-danger'],
    class: null,

    isInBeta: Ember.computed.equal('state', 'BETA'),
    isOff: Ember.computed.equal('state', 'OFF'),
    isReleased: Ember.computed.equal('state', 'RELEASED')
  });
});