define('oddset-cms/adapters/user', ['exports', 'oddset-cms/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function u_atob(ascii) {
    //decode base64 data with UTF-8
    return new TextDecoder().decode(Uint8Array.from(atob(ascii), function (c) {
      return c.charCodeAt(0);
    }));
  }

  function getTokenData(dataBase64) {
    //get the payload from jwt token (middle part separated by "." chars)
    var payload = dataBase64.authenticated.id_token.split(".")[1];

    //parse as json
    return JSON.parse(u_atob(payload));
  }

  exports.default = _application.default.extend({
    queryRecord: function queryRecord() {
      var session = this.get('session');
      if (session.get('isAuthenticated')) {
        var tokenData = getTokenData(session.get('data'));
        return {
          user: {
            id: 1,
            name: tokenData.name,
            organization_id: tokenData['custom:organization'],
            groups: tokenData['cognito:groups']
          }
        };
      }
      return { user: { id: 1, name: "N/A" } };
    }
  });
});