define('oddset-cms/components/input-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    value: null,
    text: null,

    classNames: 'form-group row'
  });
});