define('oddset-cms/models/module-layout-spec', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numberOfColumns: _emberData.default.attr('number'),
    moduleLayoutSpecModuleSpecs: _emberData.default.hasMany('module-layout-spec-module-spec'),
    moduleSpecs: Ember.computed.alias('moduleLayoutSpecModuleSpecs'),

    icon: function () {
      switch (this.get('numberOfColumns')) {
        case 1:
          return 'mobile';
        case 2:
          return 'tablet';
        case 3:
          return 'tablet';
        default:
          return 'question';
      }
    }.property('numberOfColumns'),

    columnsHumanRepresentationPostFix: function () {
      if (this.get('numberOfColumns') === 1) {
        return 'column';
      } else {
        return 'columns';
      }
    }.property('numberOfColumns')
  });
});