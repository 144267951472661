define('oddset-cms/routes/versions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        versions: this.store.findAll('version'),
        apps: this.store.peekAll('app')
      });
    }
  });
});