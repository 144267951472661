define('oddset-cms/components/create-version-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VersionFormProperties = Ember.Object.extend();

  exports.default = Ember.Component.extend({
    devicePreference: null,
    appPreference: null,
    action: function action() {},
    apps: [],

    selectedDevice: null,
    selectedApp: null,

    version: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('selectedDevice', this.get('devicePreference'));
      this.set('selectedApp', this.get('appPreference'));
    },


    actions: {
      didUpdateSelectedDevice: function didUpdateSelectedDevice(selectedDevice) {
        this.set('selectedDevice', selectedDevice);
      },
      didUpdateSelectedApp: function didUpdateSelectedApp(selectedApp) {
        this.set('selectedApp', selectedApp);
      },
      submitForm: function submitForm() {
        this.get('action')(VersionFormProperties.create({
          version: this.get('version'),
          platform: this.get('selectedDevice'),
          app: this.get('selectedApp')
        }));
      }
    }
  });
});