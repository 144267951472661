define('oddset-cms/components/configuration-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    beforeSave: function beforeSave() {},

    // Public
    config: null,
    isOpen: false,
    isSaving: false,
    showSuccessIcon: false,

    // Private
    actions: {
      saveConfiguration: function saveConfiguration() {
        var _this = this;

        this.get('beforeSave')();
        this.set('isSaving', true);
        this.get('config').save().then(function () {
          _this.set('isOpen', false);
          _this.set('isSaving', false);
          _this.set('showSuccessIcon', true);
          setTimeout(function () {
            _this.set('showSuccessIcon', false);
          }, 1500);
        }).finally(function () {
          _this.set('isSaving', false);
        });
      }
    }
  });
});