define('oddset-cms/components/configuration-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    config: null,
    showSuccessIcon: false,
    openAction: function openAction() {},

    // Private
    actions: {
      open: function open() {
        this.get('openAction')();
      }
    }
  });
});