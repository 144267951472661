define('oddset-cms/routes/callback', ['exports', 'ember-simple-auth/mixins/oauth2-implicit-grant-callback-route-mixin'], function (exports, _oauth2ImplicitGrantCallbackRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_oauth2ImplicitGrantCallbackRouteMixin.default, {

    authenticator: 'authenticator:oauth2-implicit-grant',
    activate: function activate() {
      //todo this is a hack please review
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        session.invalidate().then(function () {
          return location.reload();
        });
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});