define('oddset-cms/components/configuration-badge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Private
    tagName: 'span',
    classNames: 'badge badge-configuration-info'
  });
});