define('oddset-cms/authorizers/oauth2-bearer', ['exports', 'ember-simple-auth/authorizers/oauth2-bearer'], function (exports, _oauth2Bearer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = _oauth2Bearer.default.extend({

    //copy pasta of OAuth2BearerAuthorizer, but instead of access_token we use id_token
    authorize: function authorize(data, block) {
      var accessToken = data['id_token'];

      if (!isEmpty(accessToken)) {
        block('Authorization', 'Bearer ' + accessToken);
      }
    }
  });
});