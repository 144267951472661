define('oddset-cms/components/version-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    version: null,
    didPressNewState: function didPressNewState() {},

    classNames: ['row', 'my-2'],

    actions: {
      didPressNewState: function didPressNewState(newState) {
        this.get('didPressNewState')(this.get('version'), newState);
      }
    }
  });
});