define('oddset-cms/models/dynamic-config-field', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    fieldName: _emberData.default.attr(),
    type: _emberData.default.attr(),
    inputLabel: _emberData.default.attr(),
    shortLabel: _emberData.default.attr(),
    hasValue: Ember.computed.notEmpty('value'),

    typeModel: function () {
      return 'fields/' + Ember.String.dasherize(this.get('type'));
    }.property('type')
  });
});