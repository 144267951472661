define('oddset-cms/components/module-spec-example-configuration-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    removeModuleGroupModuleSpec: function removeModuleGroupModuleSpec() {},
    saveConfiguration: function saveConfiguration() {},

    actions: {
      removeModuleGroupModuleSpec: function removeModuleGroupModuleSpec() {
        this.get('removeModuleGroupModuleSpec')(this.get('moduleGroupSpecModuleSpec'));
      },
      saveConfiguration: function saveConfiguration() {
        this.get('saveConfiguration')(this.get('moduleGroupSpecModuleSpec'));
      }
    }
  });
});