define('oddset-cms/models/banner', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    scrollInterval: _emberData.default.attr('number'),
    aspectRatio: _emberData.default.attr('number'),

    bannerMedia: _emberData.default.hasMany('banner-medium', { async: false })
  });
});