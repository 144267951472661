define('oddset-cms/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    serializeId: function serializeId(snapshot, json, primaryKey) {
      var id = snapshot.id;
      json[primaryKey] = parseInt(id, 10);
    }
  });
});