define('oddset-cms/serializers/version', ['exports', 'oddset-cms/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        attrs: {
            version: 'number',
            appId: 'app_id'
        }
    });
});