define('oddset-cms/components/input-event-type-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    selectedEventTypes: null,
    text: null,

    // Private
    eventTypeOptions: ['tournament'],
    classNames: 'form-group'
  });
});