define('oddset-cms/components/configurations/module-configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    config: null,

    // Private
    isOpen: false,
    showSuccessIcon: false,
    isSaving: false,

    actions: {
      open: function open() {
        this.toggleProperty('isOpen');
      }
    }
  });
});