define('oddset-cms/components/module-layout-spec', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Public
    moduleLayoutSpec: null,
    moduleGroupSpec: null,

    // Private
    handle: null,
    defaultHandle: '.module-layout-spec-module-spec__handle',
    isSavingOrder: false,
    classNames: ['module-layoualyoutt-spec', 'col-md-4'],

    startSavingState: function startSavingState() {
      this.set('isSavingOrder', true);
    },
    stopSavingState: function stopSavingState() {
      this.set('isSavingOrder', false);
    },
    disableDragAndDrop: function disableDragAndDrop() {
      this.set('handle', '.__not-existing-handle');
    },
    enableDragAndDrop: function enableDragAndDrop() {
      this.set('handle', this.get('defaultHandle'));
    },


    actions: {
      droppedModuleSpec: function droppedModuleSpec(newModuleLayoutSpecModuleSpecs, draggedModuleSpec) {
        var _this = this;

        var newArrayIndex = newModuleLayoutSpecModuleSpecs.indexOf(draggedModuleSpec);
        var elementOnArrayIndex = this.get('moduleLayoutSpec.moduleSpecs').objectAt(newArrayIndex);
        draggedModuleSpec.set('orderIndex', elementOnArrayIndex.get('orderIndex'));
        this.startSavingState();
        this.set('moduleLayoutSpec.moduleLayoutSpecModuleSpecs', newModuleLayoutSpecModuleSpecs);
        draggedModuleSpec.save().finally(function () {
          _this.stopSavingState();
        });
      }
    }
  });
});