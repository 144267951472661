define('oddset-cms/models/version', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    version: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    state: _emberData.default.attr(),
    auditLogEntries: _emberData.default.hasMany('audit-log-entry', { async: false }),
    app: _emberData.default.belongsTo('app'),

    type: function () {
      return this.get('platform') + "-" + this.get('app').get('name');
    }.property('app', 'platform'),

    major: function () {
      return this.getVersionSplit(0);
    }.property('version'),

    minor: function () {
      return this.getVersionSplit(1);
    }.property('version'),

    patch: function () {
      return this.getVersionSplit(2);
    }.property('version'),

    isInBeta: Ember.computed.equal('state', 'BETA'),
    isOff: Ember.computed.equal('state', 'OFF'),
    isReleased: Ember.computed.equal('state', 'RELEASED'),

    appIndicator: function () {
      var typeSplit = this.get('type').split('-');
      if (typeSplit.length > 1) {
        return typeSplit[1];
      } else {
        return this.get('type');
      }
    }.property('type'),

    iconName: function () {
      var deviceIndicator = this.get('deviceIndicator');
      if (deviceIndicator === 'ios') {
        return 'apple';
      } else if (deviceIndicator == 'android') {
        return 'android';
      } else if (deviceIndicator == 'polling') {
        return 'rocket';
      } else {
        return 'question-circle';
      }
    }.property('deviceIndicator'),

    deviceIndicator: function () {
      return this.get('type').split('-')[0];
    }.property('type'),

    isActive: function () {
      return ['RELEASED', 'BETA'].includes(this.get('state'));
    }.property('state'),

    compareTo: function compareTo(otherVersion) {
      var majorComparison = this.compareVersionComponent(this.get('major'), otherVersion.get('major'));

      if (majorComparison !== 0) {
        return majorComparison;
      } else {
        var minorComparison = this.compareVersionComponent(this.get('minor'), otherVersion.get('minor'));

        if (minorComparison !== 0) {
          return minorComparison;
        } else {
          return this.compareVersionComponent(this.get('patch'), otherVersion.get('patch'));
        }
      }
    },
    compareVersionComponent: function compareVersionComponent(firstVersionComponent, secondVersionComponent) {
      if (firstVersionComponent === null && secondVersionComponent === null) {
        return 0;
      } else if (firstVersionComponent === null) {
        return 1;
      } else if (secondVersionComponent === null) {
        return -1;
      } else {
        return secondVersionComponent - firstVersionComponent;
      }
    },
    getVersionSplit: function getVersionSplit(index) {
      var versionSplit = this.get('version').split('.');
      if (versionSplit.length >= index + 1) {
        var stringSplit = versionSplit[index];
        var intSplit = parseInt(stringSplit);
        if (isNaN(intSplit)) {
          return null;
        } else {
          return intSplit;
        }
      } else {
        return null;
      }
    }
  });
});