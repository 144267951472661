define('oddset-cms/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    moment: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('moment').setLocale('da');
    },

    routeAfterAuthentication: 'module-group-specs'
  });
});