define('oddset-cms/models/in-running-event-group-list-spec-config', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    eventLimit: _emberData.default.attr('number'),
    eventTypesExcluded: _emberData.default.attr(),

    excludesEventTypes: Ember.computed.notEmpty('eventTypesExcluded'),
    hasModuleLimit: Ember.computed.notEmpty('limit'),
    hasEventLimit: Ember.computed.notEmpty('eventLimit'),

    formattedEventTypesExcluded: function () {
      if (this.get('excludesEventTypes')) {
        return this.get('eventTypesExcluded').join(', ');
      } else {
        return null;
      }
    }.property('eventTypesExcluded')
  });
});