define('oddset-cms/serializers/module-layout-spec-module-spec', ['exports', 'oddset-cms/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      moduleSpecConfiguration: { embedded: 'always' }
    }
  });
});