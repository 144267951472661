define('oddset-cms/adapters/module-group-spec', ['exports', 'oddset-cms/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    }
  });
});