define('oddset-cms/adapters/app', ['exports', 'oddset-cms/adapters/application', 'oddset-cms/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    host: _environment.default.VersionApi.host,
    namespace: _environment.default.VersionApi.namespace
  });
});