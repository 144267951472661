define('oddset-cms/models/text-field', ['exports', 'ember-data', 'oddset-cms/models/dynamic-config-field'], function (exports, _emberData, _dynamicConfigField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dynamicConfigField.default.extend({
    value: _emberData.default.attr(),
    hasValue: Ember.computed.notEmpty('value')
  });
});