define('oddset-cms/controllers/versions', ['exports', 'ember-group-by', 'ember-local-storage', 'ember-sweetalert/mixins/sweetalert-mixin'], function (exports, _emberGroupBy, _emberLocalStorage, _sweetalertMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VersionGroup = Ember.Object.extend({
    type: null,
    versions: [],

    iconName: function () {
      var deviceIndicator = this.get('deviceIndicator');
      if (deviceIndicator === 'ios') {
        return 'apple';
      } else if (deviceIndicator == 'android') {
        return 'android';
      } else if (deviceIndicator == 'polling') {
        return 'rocket';
      } else if (deviceIndicator == 'web') {
        return 'globe';
      } else {
        return 'question-circle';
      }
    }.property('deviceIndicator'),

    isMobileApp: function () {
      //TODO: what is this used for?
      return ['oddset', 'kuponspil'].includes(this.get('appIndicator'));
    }.property('appIndicator'),

    appIndicator: function () {
      var typeSplit = this.get('type').split('-');
      if (typeSplit.length > 1) {
        return typeSplit[1];
      } else {
        return this.get('type');
      }
    }.property('type'),

    deviceIndicator: function () {
      return this.get('type').split('-')[0];
    }.property('type')
  });

  exports.default = Ember.Controller.extend(_sweetalertMixin.default, {
    versions: Ember.computed.alias('model.versions'),
    apps: Ember.computed.alias('model.apps'),
    versionPreferences: (0, _emberLocalStorage.storageFor)('version-preferences'),

    deviceFilterValue: 'all',
    appFilterValue: 'all',
    stateFilterValue: 'all',

    isCreateFormOpen: false,

    deviceUserPreference: Ember.computed.oneWay('versionPreferences.device'),
    appUserPreference: Ember.computed.oneWay('versionPreferences.app'),
    stateUserPreference: Ember.computed.oneWay('versionPreferences.state'),

    init: function init() {
      this.set('deviceFilterValue', this.get('deviceUserPreference'));
      this.set('appFilterValue', this.get('appUserPreference'));
      this.set('stateFilterValue', this.get('stateUserPreference'));
    },


    filteredVersions: function () {
      var _this = this;

      return this.get('versions').filter(function (version) {
        return _this.isValue(version.get('deviceIndicator'), _this.get('deviceFilterValue')) && _this.isValue(version.get('appIndicator'), _this.get('appFilterValue')) && _this.isInState(version, _this.get('stateFilterValue'));
      });
    }.property('versions', 'versions.[]', 'deviceFilterValue', 'appFilterValue', 'stateFilterValue'),

    isValue: function isValue(value, filterValue) {
      return !Ember.isPresent(filterValue) || filterValue === 'all' || value === filterValue;
    },
    isInState: function isInState(version, filterValue) {
      return !Ember.isPresent(filterValue) || filterValue === 'all' || filterValue === 'active' && version.get('isActive') || filterValue === 'released' && version.get('isReleased');
    },


    versionsGroupedByType: (0, _emberGroupBy.default)('filteredVersions', 'type'),

    versionGroups: function () {
      return this.get('versionsGroupedByType').map(function (group) {
        var sortedVersions = group.items.sort(function (first, second) {
          return first.compareTo(second);
        });
        return VersionGroup.create({
          type: group.value,
          versions: sortedVersions
        });
      });
    }.property('versionsGroupedByType.[]'),

    actions: {
      didUpdateDeviceFilter: function didUpdateDeviceFilter(selectedFilter) {
        this.set('versionPreferences.device', selectedFilter);
        this.set('deviceFilterValue', selectedFilter);
      },
      didUpdateAppFilter: function didUpdateAppFilter(selectedFilter) {
        this.set('versionPreferences.app', selectedFilter);
        this.set('appFilterValue', selectedFilter);
      },
      didUpdateStateFilter: function didUpdateStateFilter(selectedFilter) {
        this.set('versionPreferences.state', selectedFilter);
        this.set('stateFilterValue', selectedFilter);
      },
      didPressNewState: function didPressNewState(version, newState) {
        var _this2 = this;

        var oldState = version.get('state');
        version.set('state', newState);
        version.save().catch(function () {
          _this2.set('state', oldState);
        });
      },
      toggleCreateForm: function toggleCreateForm() {
        this.toggleProperty('isCreateFormOpen');
      },
      createVersion: function createVersion(versionFormProperties) {
        var _this3 = this;

        var version = versionFormProperties.get('version');
        var platform = versionFormProperties.get('platform');
        var app = versionFormProperties.get('app');

        this.store.createRecord('version', {
          version: version,
          platform: platform,
          state: "BETA",
          app: app
        }).save().then(function (createdVersion) {
          var sweetAlert = _this3.get('sweetAlert');
          sweetAlert({
            title: 'The version is created and is in <span class="badge badge-warning">Beta</span>',
            type: "success",
            html: '<div class="d-inline-block"><h1>' + createdVersion.get("version") + '</h1></div>' + ('<div class="d-inline-block"><div class="version__type-icon"><i class="fa fa-' + createdVersion.get("iconName") + '"></i></div></div>') + ('<div class="d-inline-block"><img src="/assets/images/icon-' + createdVersion.get("appIndicator") + '.jpg" class="version__form-success-type-app-icon" /></div>')
          }).then(function () {
            _this3.set('isCreateFormOpen', false);
            _this3.store.findAll('version', { reload: true }).then(function (versions) {
              _this3.set('versions', versions.toArray());
            });
          });
        }).catch(function (error) {
          var statusCodeString = error.errors[0].status;

          if (statusCodeString === "409") {
            var sweetAlert = _this3.get('sweetAlert');
            sweetAlert({
              type: "error",
              title: "This version already exists."
            });
          }
        });
      }
    }
  });
});