define('oddset-cms/storages/user-preferences', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  exports.default = Storage;
});