define('oddset-cms/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    organizationId: _emberData.default.attr(),
    groups: _emberData.default.attr(),
    email: _emberData.default.attr(),

    hasRaceRole: computed('groups', function () {
      return this.get('groups') && this.get('groups').includes('race');
    }),

    hasVersionRole: computed('groups', function () {
      return this.get('groups') && this.get('groups').includes('version');
    }),

    hasModuleRole: computed('groups', function () {
      return true; //this.get('groups') && this.get('groups').includes('module');
    })
  });
});